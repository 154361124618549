'use client';

import { FC, PropsWithChildren, ReactElement } from 'react';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { Provider as StoreProvider } from 'react-redux';

import { MenuBar } from '@/compositions';
import {
    ConnectedFooter,
    ConnectedToastList,
    ConnectedTopNavbar,
    ConnectedUrgentBanner,
} from '@/connectors';
import CookieProvider from '@/context/CookieContext';
import LocaleProvider from '@/context/LocaleContext';
import { isProduction } from '@/helpers';
import { store } from '@/redux/store';

import packageJson from '../../package.json';

import '../styles/global.scss';
import './layout.scss';

if (process.env.NEXT_PUBLIC_BUGSNAG_API_KEY) {
    Bugsnag.start({
        apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
        plugins: [new BugsnagPluginReact()],
        appVersion: isProduction ? packageJson.version : 'staging',
        appType: 'browser',
        redactedKeys: [
            'token',
            'password',
        ],
    });
}

const Layout: FC<PropsWithChildren> = ({
    children,
}): ReactElement => (
    // eslint-disable-next-line react/no-unknown-property
    <html lang="nl" dir="ltr" prefix="og: https://ogp.me/ns#">
        <head>
            <link rel="stylesheet" href="https://use.typekit.net/ylw8azo.css" />
        </head>
        <body>
            <StoreProvider store={store}>
                <CookieProvider>
                    <LocaleProvider>
                        <div id="app-root">
                            <ConnectedTopNavbar />
                            <MenuBar className="layout__menu-bar" />

                            <ConnectedUrgentBanner />

                            {children}

                            <ConnectedToastList />
                            <ConnectedFooter />
                        </div>

                        <div id="portal-root" />
                    </LocaleProvider>
                </CookieProvider>
            </StoreProvider>
        </body>
    </html>
);

export default Layout;
