import { FC, ReactElement } from 'react';

import { Footer } from '@/containers';
import { transformToFooterNavigation } from '@/entities/Footer/FooterTransformers';
import { MOCK_FOOTER_NAVIGATION } from '@/mock/mock-data';

export const ConnectedFooter: FC = (): ReactElement => {
    const mockFooterNavigation = transformToFooterNavigation(MOCK_FOOTER_NAVIGATION);

    return (
        <Footer footerNavigation={mockFooterNavigation} />
    );
};
